import React from 'react';

function EOA() {
  const reports = [
    { year: '2009-10', link: 'EOA 2009-2010.pdf' },
    { year: '2010-11', link: 'EOA 2010-11.pdf' },
    { year: '2011-12', link: 'EOA 2011-12.pdf' },
    { year: '2012-13', link: 'EOA -2012-13.pdf' },
    { year: '2014-15', link: 'EOA -2014-15.pdf' },
    { year: '2015-16', link: 'EOA -2015-16.pdf' },
    { year: '2016-17', link: 'EOA -2016-17.pdf' },
    { year: '2017-18', link: 'EOA-2017-18.pdf' },
    { year: '2018-19', link: 'EOA-2018-19.pdf' },
    { year: '2019-20', link: 'EOA-2019-20.pdf' },
    { year: '2020-21', link: 'EOA-2020-21.pdf' },
    { year: '2021-22', link: 'EOA-2021-22.pdf' },
    { year: '2022-23', link: 'EOA-2022-23.pdf' },
    { year: '2024-25', link: 'EOA-Report-2024-2025.pdf' },
  ];

  return (
    <div>
      <section id="contact" className="contact section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>AICTE EOA LETTERS</h2>
            <p>AICTE EOA LETTERS</p>
          </div>
          <ul className='list-unstyled'>
            <li>   <a href={`/assets/img/pdfs/LOI -2009.pdf`} target="_blank" rel="noopener noreferrer"><strong><i class="bi bi-file-earmark-pdf-fill"></i> Click Here for LOI Report 2009</strong> </a></li>
            {reports.map((report, index) => (
              <li key={index}>
                <a href={`/assets/img/pdfs/${report.link}`} target="_blank" rel="noopener noreferrer" ><strong><i class="bi bi-file-earmark-pdf-fill"></i>  Click Here for EOA Report {report.year}</strong></a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
}

export default EOA;
